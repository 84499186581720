import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from "../components/shared/layout"
import aboutImage from '../images/khalil-headshot-long.png'
import { SmallSubscribeForm } from '../components/subscribe'

const AboutImage = () => (
  <img src={aboutImage}/>
)

const About = ({ content }) => (
  <Layout 
    title="About"
    seo={{
      title: 'About',
      keywords: ['khalil stemmler']
    }}
    newsletterActive={false}
    component={(
      <div className="desktop-subscribe-form-container">
        <SmallSubscribeForm/>
      </div>
    )}>

    <AboutImage/>

    <div dangerouslySetInnerHTML={{ __html: content}}/>
  
  </Layout>
)

export default () => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: {templateKey: { eq: "about"}}) {
          html
        }
      }
    `}
    render={data => {
      const content = data.markdownRemark.html;
      return (
        <About
          content={content}
        />
      )
    }}
  />
)